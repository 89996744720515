import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
// ...GatsbyImageSharpFluid

const ProjectsPage = ({
  data:{allStrapiProjects:{nodes:projects}}
}) => {
   
  return (
      <Layout>
        <section className="projects-page">
          <Projects projects = {projects} title="all projects"/>
        </section>
      </Layout>
  
    )
}

 
export const query = graphql`
  {
    allStrapiProjects {
      nodes {
        github
        id
        description
        title
        url
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        stack {
          id
          title
          stackimage {
            childImageSharp {
              fixed(height: 20) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
          }
        }
      }
    }
  }
`

export default ProjectsPage
